:root {
  --border-radius: 15px;

  --white-color: #fff;
  --black-color: #000;

  --main-button-color: rgb(0, 151, 178);
  --secondary-button-color: black;
  --font-light: white;
  --font-dark: black;
  --accent: rgb(255, 86, 0);



  --separator: 1px solid rgba(0,0,0,0.15);
  --header-height: 66vh;
  --header-no-logo-height: 50vh;

  --padding: 30px;
  --button-padding: 12px; 
  --small-padding: 12px;
  --medium-padding: 20px;
  --large-padding: 30px;

  --marker-size: 12px;
  --inverse-marker-size: calc(var(--marker-size) * -1);
}

.leaflet-div-icon {
  position: absolute;
  color: var(--font-dark);
  border-radius: 50%;
  border: var(--marker-size) solid var(--accent);
  top: calc(var(--inverse-marker-size) * 3);
  left: var(--inverse-marker-size);
  width: 0px;
  height: 0px;
  margin-left: 0 !important;
  margin-top: 0 !important;
}

.leaflet-div-icon svg {
  position: absolute;
  left: calc(var(--inverse-marker-size) * 0.6);
  top: calc(var(--inverse-marker-size) / 2);
  font-size: var(--marker-size);
  width: 1.2em;
}

.leaflet-div-icon img {
  position: absolute;
  top: var(--inverse-marker-size);
  left: var(--inverse-marker-size);
  width: calc(var(--marker-size) * 2);
}

.leaflet-div-icon::before {
  position: relative;
  content: '';
  width: 0px;
  height: 0px;
  margin-right: calc(var(--inverse-marker-size) * 3);
  bottom: calc(var(--inverse-marker-size) * 2);
  left: var(--inverse-marker-size);
  border: var(--marker-size) solid transparent;
  border-top: calc(var(--marker-size) * 1.8) solid var(--accent);
}

h1 {
	font-size: 1.5rem;
}

h2 {
	font-size: 1.4rem;
}

h3 {
	font-size: 1.3rem;
}

h4 {
	font-size: 1.05rem;
}

h5 {
	font-size: 1.0rem;
}

h6 {
	font-size: 0.8rem;
}

@media (max-width: 600px), (max-height: 800px) {
  :root {
    --padding: 15px;
	--small-padding: 5px;
  }
}

.App {
  overflow: hidden;
}

.shape {
  position: absolute;
  z-index: -1;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--button-padding);
  border-radius: var(--border-radius);
  background: var(--main-button-color);
  color: var(--font-light);
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.roundButton {
  border-radius: 100%;
}

.largeButton {
  width: 3rem;
  height: 3rem;
  font-size: 22px;
}

.accentButton {
  background: var(--accent);
}

.button:hover {
  background-color: #000;
  color: #fff;
}

.casing {
  text-transform: capitalize;
}

.guestIcon {
  margin-right: var(--small-padding);
  color: rgba(0,0,0,0.25);
  height: 1em;
}

.mapSection {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: var(--small-padding);
}

.mapSection img {
  width: 75px;
}

.socialLinks {
	display: flex;
	font-size: 28px;

	a {
		margin-right: var(--padding);
	}

	a:hover {
		filter: brightness(1.5);
		color: var(--accent);
	}

	a:visited {
		color: var(--accent);
	}
}
