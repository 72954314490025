html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  }

@import url('https://rsms.me/inter/inter.css');
  html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(67, 67, 67);
  font-size: 17px;
}

h1, h2, h3, h4, h5 {
  color: var(--black-color);
  margin-bottom: var(--small-padding);
}

p {
  margin: 0;
  margin-bottom: var(--padding);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link {
  color: var(--accent);
}

a:hover {
  color: var(--black-color);
}

.columns {
  display: flex;
  flex-direction: row;
  margin-bottom: var(--padding);
}
.column {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: var(--padding);
}
.flex-center {
  justify-content: center;
  align-items: center;
}
.column:last-of-type {
  padding-right: 0;
}

@media (max-width: 800px) {
.columns {
  flex-direction: column;
  margin-bottom: 0;
  }
  .column {
    padding-right: 0;
  }
}

.seperator {
  height: 1px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  margin: var(--padding) 0;
}
